import React from "react";
import PropTypes from "prop-types";

const SharingButton = (props) => (
  <a
    className={`flex gap-2 items-center ${props.bgColor} px-4 rounded-[4px] text-[11px] h-[32px] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:${props.bgColor} duration-300 font-sans`}
    href={props.fullUrl}
    target="_blank"
    rel="noopener noreferrer"
    onClick={props.onClick}
  >
    {props.icon}
    <span>{props.text}</span>
  </a>
);

SharingButton.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  fullUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  bgColor: PropTypes.string.isRequired,
};

export default SharingButton;
