import React from "react";

const FormWrap = ({
  handleImageUpload,
  setName,
  name,
  bounty,
  setBounty,
  downloadCanvas,
  setFormData,
  setIsModalOpen,
}) => {
  return (
    <div className="flex justify-center items-center gap-8 flex-col xl:w-[80%]  relative mx-auto">
      <h2 className="lg:text-[40px] md:text-[40px] text-[30px]  font-bold text-center  text-[#534026]">
        Generate your{" "}
        <span className="text-[#E9DEC0] lg:text-[40px] md:text-[40px] text-[30px]  font-bold">
          One Piece
        </span>{" "}
        wanted poster
      </h2>
      <p className="text-center text-[#E9DEC0] text-[16px]">
        Create a custom One Piece wanted poster to share with your friends!
      </p>
      <div className="flex flex-col gap-2 justify-center items-center w-full">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full py-[10px] px-[15px] border border-[#dee2e6] rounded focus:border-[#E9DEC0] focus-visible:border-[#E9DEC0] focus-visible:outline-none text-center hover:border-[#E9DEC0] uppercase "
        />
      </div>
      <div className="flex flex-col gap-2 justify-center items-center w-full">
        <label htmlFor="bounty">Bounty</label>
        <input
          type="number"
          value={bounty}
          onChange={(e) => setBounty(e.target.value)}
          name="bounty"
          className="w-full py-[10px] px-[15px] border border-[#dee2e6] rounded focus:border-[#E9DEC0] focus-visible:border-[#E9DEC0] focus-visible:outline-none text-center hover:border-[#E9DEC0]"
        />
      </div>

      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        className="hidden"
        id="fileInput"
      />
      <label
        htmlFor="fileInput"
        className="py-[10px] px-[15px] bg-[#534026] w-full rounded text-white hover:bg-[#534026] text-center cursor-pointer"
      >
        Upload image
      </label>
      <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-3">
        <button
          className="py-[10px] px-[15px] bg-[#534026] w-full rounded text-white hover:bg-[#534026]"
          onClick={() => downloadCanvas(true)}
        >
          Download With Watermark
        </button>
        <button
          className="py-[10px] px-[15px] bg-[#534026] w-full rounded text-white hover:bg-[#534026]"
          onClick={() => setIsModalOpen(true)}
        >
          Download Without Watermark
        </button>
      </div>
    </div>
  );
};

export default FormWrap;
