import React from "react";
import Brook from "../assets/Brook's_Concert_Wanted_Poster.png";
import Franky from "../assets/Franky's_Wanted_Poster.png";
import Usopp from "../assets/God_Usopp's_Wanted_Poster.png";
import Jinbe from "../assets/Jinbe's_Wanted_Poster.png";
import Monkey from "../assets/Monkey_D._Luffy's_Wanted_Poster.png";
import Nami from "../assets/Nami's_Wanted_Poster.png";
import Nico from "../assets/Nico_Robin's_Wanted_Poster.png";
import Roronoa from "../assets/Roronoa_Zoro's_Wanted_Poster.png";
import Sanji from "../assets/Sanji's_Wanted_Poster.png";
import Tony from "../assets/Tony_Tony_Chopper's_Wanted_Poster.png";

const Pirates = () => {
  const posters = [
    Monkey,
    Roronoa,
    Nami,
    Usopp,
    Sanji,
    Tony,
    Nico,
    Franky,
    Brook,
    Jinbe,
  ];

  return (
    <div className="py-[50px] flex flex-col gap-6">
      <div className="text-center">
        <h2 className="lg:text-[40px] md:text-[40px] text-[30px]   font-bold   text-[#534026]">
          Generate a custom One Piece wanted poster
        </h2>
        <p className="text-[#E9DEC0] text-[20px]">
          Join your fellow pirates and become an inamous enemy of the World
          Government!
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4">
        {posters.map((poster, index) => (
          <img
            src={poster}
            alt=""
            key={index + 1}
            className="w-[385px] h-[545px]"
          />
        ))}
      </div>
    </div>
  );
};

export default Pirates;
