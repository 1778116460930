import React from "react";
import PropTypes from "prop-types";
import { FaWhatsapp } from "react-icons/fa";
import encodeURI from "../../utils/encodeURI";
import SharingButton from "../SharingButton";

const WhatsApp = (props) => {
  const text = props.text || "Share";
  const shareText = encodeURI(props.shareText);
  const fullUrl = `https://wa.me/?text=${shareText}`;

  return (
    <SharingButton
      type="whatsapp"
      onClick={props.onClick}
      icon={<FaWhatsapp />}
      text={text}
      fullUrl={fullUrl}
      bgColor={"bg-[#25D366]"}
    />
  );
};

WhatsApp.propTypes = {
  text: PropTypes.string,
  shareText: PropTypes.string,
  onClick: PropTypes.func,
};

export default WhatsApp;
