import React from "react";
import PropTypes from "prop-types";
import { FaFacebookMessenger } from "react-icons/fa";
import encodeURI from "../../utils/encodeURI";
import SharingButton from "../SharingButton";

const Messenger = (props) => {
  const text = props.text || "Share";
  const url = encodeURI(props.url);
  const fullUrl = `https://www.messenger.com/t/?link=${url}`;

  return (
    <SharingButton
      type="messenger"
      onClick={props.onClick}
      icon={<FaFacebookMessenger />}
      text={text}
      fullUrl={fullUrl}
      bgColor={"bg-[#0084FF]"}
    />
  );
};

Messenger.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
};

export default Messenger;
