import React from "react";


const WantedPoster = ({ canvasRef }) => {
  return (
    <div className="flex justify-center items-center  xl:h-[calc(100vh-50px)] h-full relative">
      <canvas
        ref={canvasRef}
        width={1754}
        height={2481}
        className="aspect-[1754/2481] h-full max-xl:w-full max-xl:h-auto shadow-[0_0_10px_rgba(0,0,0,0.7)]"
      ></canvas>
    </div>
  );
};

export default WantedPoster;
