import React from "react";
import PropTypes from "prop-types";
import { FaTelegramPlane } from "react-icons/fa";
import encodeURI from "../../utils/encodeURI";
import SharingButton from "../SharingButton";

const Telegram = (props) => {
  const text = props.text || "Share";
  const shareText = encodeURI(props.shareText);
  const fullUrl = `https://t.me/share/url?text=${shareText}`;

  return (
    <SharingButton
      type="telegram"
      onClick={props.onClick}
      icon={<FaTelegramPlane />}
      text={text}
      fullUrl={fullUrl}
      bgColor={"bg-[#0088cc]"}
    />
  );
};

Telegram.propTypes = {
  text: PropTypes.string,
  shareText: PropTypes.string,
  onClick: PropTypes.func,
};

export default Telegram;
